import useSWR from 'swr'

const host =
  process.env.REACT_APP_API_HOST || "https://lukola.lukola.workers.dev";

export const fetcher = (url) => fetch(url, {
  headers: {
    Authentication: sessionStorage.getItem("token"),
  }
}).then(res => res.json()).catch(err => err)

export const login = async (username, password) => {
  try {
    const response = await fetch(host + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username, password }),
    });
    const body = await response?.json();

    if (response?.ok) {
      const { token } = body;
      sessionStorage.setItem("token", token);
      return {};
    } else {
      return { error: body.error };
    }
  } catch (error) {
    return { error: error.message };
  }
};

export const valid = async () => {
  return (
    sessionStorage.getItem("token") &&
    (
      await fetch(host + "/validate/" + sessionStorage.getItem("token")).then(
        (r) => r.json()
      )
    ).valid
  );
};

export const saveProduct = async (product) => {
  try {
    const response = await fetch(host + "/products", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authentication: sessionStorage.getItem("token"),
      },
      body: JSON.stringify(product),
    });
    const body = await response?.json();

    if (response?.ok) {
      return body;
    } else {
      return { error: JSON.stringify(body) };
    }
  } catch (error) {
    return { error: error.message };
  }
};

export const deleteProduct = async (id) => {
  try {
    const response = await fetch(host + "/products/" + id, {
      method: "DELETE",
    });
    if (!response?.ok) {
      return {};
    } else {
      return response.statusText;
    }
  } catch (error) {
    return { error: error.message };
  }
};

export const publishHook = async () => {
  try {
    const response = await fetch(
      "https://api.cloudflare.com/client/v4/pages/webhooks/deploy_hooks/6d9a1501-a392-48ce-afb1-3fd3f43b8e5b",
      {
        method: "POST",
        mode: "no-cors",
      }
    );
    if (response?.ok) {
      const { success, errors, messages } = await response.json();

      if (!success || errors?.length > 0) {
        return { error: errors.toString() || messages.toString() };
      }

      return {};
    } else {
      return { error: response.statusText };
    }
  } catch (error) {
    return { error: error.message };
  }
};

export const usePurchases = () => useSWR(host + "/purchases", fetcher);

export const useProducts = () => {
  const { data, error, isLoading } = useSWR(host + "/products", fetcher);
  return { data, error, isLoading }
}

export const useQuantity = () => {
  const { data, error, isLoading } = useSWR(host + "/quantity", fetcher);
  return { data, error, isLoading };
}
