import { useState } from "react";
import { login } from "./http";

export default function Login({ setIsLoggedIn }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");

  const onSubmit = async (e) => {
    e.preventDefault();

    const { error } = await login(username, password);
    
    if (error) {
      setErrors(error);
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  };

  return (
    <form className="max-w-sm m-auto" onSubmit={onSubmit}>
      <label className="flex items-center border-b border-turquoise py-2">
        <input
          placeholder="email"
          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
          type="email"
          name="username"
          onInput={(e) => setUsername(e.target.value)}
        />
      </label>
      <label className="flex items-center border-b border-turquoise py-2">
        <input
          placeholder="pass"
          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
          type="password"
          name="password"
          onInput={(e) => setPassword(e.target.value)}
        />
      </label>
      <button
        type="submit"
        className="block w-full p-2 mt-8 bg-turquoise text-white "
      >
        Login
      </button>

      {errors && <pre>{errors}</pre>}
    </form>
  );
}
