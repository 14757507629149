import { useEffect, useState } from "react";
import "./App.css";
import Products from "./products";
import { valid } from "./http";
import Login from "./login";
import Purchases from "./purchases";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [tab, setTab] = useState("purchases");

  useEffect(() => {
    (async () => {
      setIsLoggedIn(!!await valid());
    })();
  }, [tab]);

  return (
    <>
      {isLoggedIn ? (
        <>
          <header>
            <nav className="mb-8 border-b border-mustard">
              <ul className="flex justify-center list-none">
                <li className="p-4">
                  <button
                    className={`${tab === "purchases" && "text-mustard"}`}
                    onClick={() => setTab("purchases")}
                  >
                    Purchases
                  </button>
                </li>
                <li className="p-4">
                  <button
                    className={`${tab === "products" && "text-mustard"}`}
                    onClick={() => setTab("products")}
                  >
                    Products
                  </button>
                </li>
                {/* <li className="p-4">
                  <button
                    className={`${tab === "pages" && "text-mustard"}`}
                    onClick={() => setTab("pages")}
                  >
                    Pages
                  </button>
                </li> */}
              </ul>
            </nav>
          </header>
          {tab === "purchases" && (
            <>
              <Purchases />
            </>
          )}
          {tab === "products" && (
            <>
              <Products />
            </>
          )}
          {tab === "pages" && (
            <>pages</>
          )}
        </>
      ) : (
        <Login setIsLoggedIn={setIsLoggedIn} />
      )}
    </>
  );
}

export default App;
