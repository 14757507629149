import { useState } from "react";
import { usePurchases } from "./http";
import PurchaseDetails from "./purchaseDetails";
import Spinner from "./spinner";

const Purchases = () => {
  const { data, error, isLoading } = usePurchases();
  const [purchase, setPurchase] = useState(null);

  const openDetails = (purchase) => () => setPurchase(purchase);
  const closeDetails = () => setPurchase(null);

  if (isLoading) return <Spinner />;
  if (error) return <>{JSON.stringify({ error }, null, 2)}</>;

  const purchases = data.sort((a, b) => b.purchaseNumber - a.purchaseNumber);

  return (
    <div className="p-4">
      <table className="w-full">
        <thead>
          <tr>
            <th className="w-16">Purchase</th>
            <th>Items</th>
            <th>Sum</th>
          </tr>
        </thead>
        <tbody>
          {purchases.map((purchase, i) => (
            <tr key={i} onClick={openDetails(purchase)} className="cursor-pointer">
              <td>{purchase.purchaseNumber?.toString().padStart(3, "0")}</td>
              <td>{purchase.items.map(({ title }) => title).join(", ")}</td>
              <td className="text-right">{purchase.itemsValue + purchase.deliveryCost} PLN</td>
            </tr>
          ))}
        </tbody>
      </table>
      { purchase && <PurchaseDetails handleClose={closeDetails} purchase={purchase} />}
    </div>
  );
};

export default Purchases;
