import { useEffect, useState, useMemo } from "react";
import TextareaAutosize from "react-textarea-autosize";
import Cloudinary from "./cloudinary";
import { deleteProduct, publishHook, saveProduct, useProducts, useQuantity } from "./http";
import Spinner from "./spinner";

const init = {
  id: "",
  category: "",
  title: "",
  lead: "",
  price: "",
  oldPrice: "",
  isUnique: false,
  oneSize: "",
  xs: "",
  s: "",
  m: "",
  l: "",
  xl: "",
  details: "",
  description: "",
  photos: [],
  postDelivery: "",
  parcelDelivery: "",
};

const Products = () => {
  const { data, error: loadingError, isLoading } = useProducts();
  const { data: quantity } = useQuantity();
  const [error, setError] = useState("");
  const [form, setForm] = useState(init);
  const [assets, setAssets] = useState();
  const [products, setProducts] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [onlySoldOut, setOnlySoldOut] = useState(false);

  useEffect(() => setError(""), [form]);
  useEffect(() => assets && setForm({ ...form, photos: assets.map((p) => p.secure_url)}), [assets]);
  useMemo(() => quantity && setProducts(data?.map((p) => ({ ...p, ...quantity[p.id] })) ?? []), [data, quantity]);

  if (isLoading || !quantity) return <Spinner />;
  if (loadingError) return <>{JSON.stringify({ loadingError }, null, 2)}</>;

  const onSubmit = async (e) => {
    e.preventDefault();
    setError("");

    if (products.some((p) => p.title === form.title && p.id !== form.id)) {
      return setError("There is a product with this name already");
    }

    if (form.oneSize && (form.xs || form.s || form.m || form.l || form.xl)) {
      return setError("It can't be one-sized and have other sizes as well. Duh");
    }

    const payload = { ...form };

    payload.price = parseFloat(payload.price) || 0;

    clearPayload(payload, ["oldPrice", "postDelivery", "parcelDelivery", "oneSize", "xs", "s", "m", "l", "xl"]);

    setIsSaving(true);
    const { id, error } = await saveProduct(payload);
    setIsSaving(false);

    if (id) {
      const product = { ...payload, id };
      const index = products.findIndex((p) => p.id === id);
      setProducts([...products.slice(0, index < 0 ? 0 : index), product, ...products.slice(index + 1)]);
      edit(product)();
    }

    if (error) {
      setError(error);
    }
  };

  const onChange = (e) => {
    setError("");
    const key = e.target.name;
    let value = "";

    switch (e.target.type) {
      case "checkbox":
        value = e.target.checked;
        break;
      case "number":
        value = parseFloat(e.target.value);
        break;
      default:
        value = e.target.value;
        break;
    }

    setForm({
      ...form,
      [key]: value,
    });
  };

  const create = () => setForm(init);
  const edit = (product) => () => setForm({ ...init, ...product });

  const duplicate = () => {
    // eslint-disable-next-line no-unused-vars
    const { id, ...values } = form;
    setForm({ ...values, title: `${Math.random().toString(16).slice(3, 6)} - ${values.title}` });
  };

  const remove = async () => {
    await deleteProduct(form.id);

    setProducts(products.filter((p) => p.id !== form.id));
    create();
  };

  const removePhoto = (photo) => () => setForm({ ...form, photos: form.photos.filter((p) => p !== photo) });

  let sortEnter = -1;

  const onDragEnter = (index) => () => {
    sortEnter = index;
  };

  const onDragEnd = (index) => (e) => {
    const filtered = [...form.photos.slice(0, index), ...form.photos.slice(index + 1)];

    setForm({
      ...form,
      photos: [...filtered.slice(0, sortEnter), form.photos[index], ...filtered.slice(sortEnter)],
    });
  };

  const publish = async () => {
    const { error } = await publishHook();

    if (error) {
      console.log(error);
      return alert("Couldn't publish. More details in console");
    }
  };

  return (
    <div>
      <div className="flex">
        <button className="bg-red-800 text-white py-1 px-3 mx-auto inline-block uppercase text-sm shadow" onClick={publish}>
          Publish
        </button>
      </div>
      <hr className="my-4" />
      <section className="flex m-auto mb-24">
        <aside className="w-1/3 mx-4">
          <ul className="w-full">
            <li>
              <label className="cursor-pointer text-turquoise-light text-sm leading-10">
                <input className="accent-turquoise-light mr-2" type="checkbox" onChange={({ target: { checked } }) => setOnlySoldOut(checked)} /> Sold out
              </label>
              <hr className="my-4"/>
            </li>
            <li>
              <button type="button" className="bg-turquoise-light text-white py-1 px-6 mt-2 mb-4 inline-block uppercase text-sm shadow" onClick={create}>
                New
              </button>
              <hr className="mt-2 mb-4"/>
            </li>
            {products.filter(checkHasQuantity(onlySoldOut)).map((product) => (
              <li key={product.id}>
                <button
                  className={`overflow-hidden whitespace-nowrap text-left ${form.id === product.id && "text-mustard"}`}
                  type="button"
                  onClick={edit(product)}
                >
                  {product.title}
                </button>
              </li>
            ))}
          </ul>
        </aside>
        <main className="mx-4">
          <form onSubmit={onSubmit}>
            <label className="flex w-full border-b border-turquoise py-2">
              <input
                placeholder="category"
                name="category"
                className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                onInput={onChange}
                value={form.category}
              />
            </label>
            <label className="flex w-full border-b border-turquoise py-2">
              <input
                placeholder="title"
                name="title"
                className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                onInput={onChange}
                value={form.title}
              />
            </label>
            <label className="flex w-full border-b border-turquoise py-2">
              <input
                placeholder="lead"
                name="lead"
                className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                onInput={onChange}
                value={form.lead}
              />
            </label>
            <div className="flex">
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="price"
                  name="price"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  onInput={onChange}
                  value={form.price}
                />
              </label>
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="old price"
                  name="oldPrice"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  onInput={onChange}
                  value={form.oldPrice}
                />
              </label>
              <div className="mt-3">
                <label className="inline-flex relative items-center cursor-pointer">
                  <input name="isUnique" type="checkbox" className="sr-only peer" onChange={onChange} checked={form.isUnique} />
                  <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-turquoise"></div>
                  <span className="ml-3 text-gray-700 dark:text-gray-300">unique</span>
                </label>
              </div>
            </div>
            <div className="flex">
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="one size"
                  name="oneSize"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  min={0}
                  onInput={onChange}
                  value={form.oneSize}
                />
              </label>
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="xs"
                  name="xs"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  min={0}
                  onInput={onChange}
                  value={form.xs}
                />
              </label>
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="s"
                  name="s"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  min={0}
                  onInput={onChange}
                  value={form.s}
                />
              </label>
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="m"
                  name="m"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  min={0}
                  onInput={onChange}
                  value={form.m}
                />
              </label>
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="l"
                  name="l"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  min={0}
                  onInput={onChange}
                  value={form.l}
                />
              </label>
              <label className="w-full border-b border-turquoise py-2">
                <input
                  placeholder="xl"
                  name="xl"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  min={0}
                  onInput={onChange}
                  value={form.xl}
                />
              </label>
            </div>
            <div className="flex">
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="Inpost parcel delivery"
                  name="parcelDelivery"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  onInput={onChange}
                  value={form.parcelDelivery}
                />
              </label>
              <label className="w-full border-b border-turquoise py-2 mr-4">
                <input
                  placeholder="Polish Post delivery"
                  name="postDelivery"
                  className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                  type="number"
                  onInput={onChange}
                  value={form.postDelivery}
                />
              </label>
            </div>
            <label className="flex w-full border-b border-turquoise py-2">
              <TextareaAutosize
                minRows={2}
                placeholder="technical details"
                name="details"
                className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                onInput={onChange}
                value={form.details}
              />
            </label>
            <label className="flex w-full border-b border-turquoise py-2">
              <TextareaAutosize
                minRows={2}
                placeholder="description"
                name="description"
                className="appearance-none bg-transparent border-none w-full text-gray-700 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                onInput={onChange}
                value={form.description}
              />
            </label>
            <div className="mt-4">
              <Cloudinary setAssets={setAssets} />
            </div>
            <div className="flex">
              {form.photos.map((photo, i) => (
                <div key={i} className="p-2 w-24 h-24" onDragEnter={onDragEnter(i)} onDragEnd={onDragEnd(i)}>
                  <img src={photo} width={64} height={64} onDoubleClick={removePhoto(photo)} alt="" />
                </div>
              ))}
            </div>
            <div className="flex justify-between mt-8">
              <button
                type="submit"
                className={`bg-turquoise text-white py-3 px-8 inline-block yar text-sm shadow disabled:opacity-20 ${isSaving && "animate-bounce"}`}
                disabled={isSaving || error || !(form.title && form.price)}
              >
                {form.id ? "Update" : "Create"}
              </button>
              {form.id ? (
                <div>
                  <button type="button" className="bg-turquoise text-white py-1 px-3 mr-3 inline-block uppercase text-sm shadow" onClick={duplicate}>
                    Duplicate
                  </button>
                  <button type="button" className="bg-red-800 text-white py-1 px-3 inline-block uppercase text-sm shadow" onClick={remove}>
                    Delete
                  </button>
                </div>
              ) : null}
            </div>
            {error ? <div className="text-red-700">{error}</div> : null}
          </form>
        </main>
      </section>
    </div>
  );
};

export default Products;

function clearPayload(payload, keys = []) {
  keys.forEach((key) => {
    if (payload[key]) {
      payload[key] = parseFloat(payload[key]);
    } else {
      delete payload[key];
    }
  });
}

function checkHasQuantity(onlySoldOut) {
  return function (product) {
    return onlySoldOut ^ !!(product.oneSize || product.xs || product.s || product.m || product.l || product.xl);
  }
}
