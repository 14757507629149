import React, { useEffect } from "react";

let onlyOnce = true;

const Cloudinary = ({ setAssets }) => {
  useEffect(() => {
    if (onlyOnce) {
      onlyOnce = false;
      const js = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];

      js.defer = true;
      js.src = "https://media-library.cloudinary.com/global/all.js";
      js.onload = () => {
        window.ml = window.cloudinary.createMediaLibrary(
          {
            cloud_name: "dxrrzw9d4",
            api_key: 499656541956638,
            multiple: true,
          },
          {
            insertHandler: function (data) {
              setAssets(data.assets);
            },
          },
          "#cl"
        );
      };

      body.appendChild(js);
    }
  }, [setAssets]);

  const open = () => setTimeout(() => window.ml.show(), 200);

  return <button id="cl" type="button" onClick={open}>Open Media Library</button>;
};

export default Cloudinary;
