import React from "react";

export default function PurchaseDetails({ purchase, handleClose }) {
  console.log(purchase);
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm">
        <div className="relative w-auto my-6 mx-auto max-w-6xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none  max-h-[90vh]">
            {/*header*/}
            <div className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl mb-0">Purchase {purchase.purchaseNumber?.toString().padStart(3, "0")}</h3>
              <button
                className="ml-auto bg-transparent border-0 text-black float-right text-xl leading-none outline-none focus:outline-none"
                onClick={handleClose}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
              </button>
            </div>
            {/*body*/}
            <div className="relative p-6 flex-auto overflow-auto">
              <table className="w-full my-4">
                <tbody>
                  {purchase.items.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <img src={item.photos[0]} width={64} height={64} alt="" />
                      </td>
                      <td>
                        <h5 className="font-bold">{item.title}</h5>
                      </td>
                      <td>
                        {item.price} {item.oldPrice ? <span className="line-through">({item.oldPrice})</span> : ""} PLN
                      </td>
                      <td>
                        szt.:{" "}
                        {item.hasOneSize ? (
                          item.quantity
                        ) : (
                          <>
                            {item.xs > 0 ? "xs: " + item.xs : ""},{item.s > 0 ? "s: " + item.s : ""},{item.m > 0 ? "m: " + item.m : ""},
                            {item.l > 0 ? "l: " + item.l : ""},{item.xl > 0 ? "xl: " + item.xl : ""}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex justify-evenly space-x-2">
                <div className="border border-turquise-light p-4">
                  <h5 className="font-semibold">Delivery</h5>
                  <p>{purchase.delivery.fullname}</p>
                  <p>
                    <a href={"mailto:" + purchase.delivery.email}>{purchase.delivery.email}</a>
                  </p>
                  <p>
                    <a href={"tel:" + purchase.delivery.phone}>{purchase.delivery.phone}</a>
                  </p>
                  <p>type: {purchase.delivery.type}</p>
                  {purchase.delivery.point ? <p>{purchase.delivery.point}</p> : ""}
                  {purchase.delivery.street ? <p>{purchase.delivery.street}</p> : ""}
                  {purchase.delivery.zip ? (
                    <p>
                      {purchase.delivery.zip} {purchase.delivery.city}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`border border-turquise-light p-4 ${
                    purchase.notifications[0].status === "CONFIRMED" ? "bg-green-100" : purchase.notifications[0].status === "NEW" ? "" : "bg-red-100"
                  }`}
                >
                  <h5 className="font-semibold">Summary</h5>
                  <p>amount: {purchase.itemsValue} PLN</p>
                  <p>delivery: {purchase.deliveryCost} PLN</p>
                  <p>status: {purchase.notifications[0].status}</p>
                  <p>{purchase.payment.response.paymentId}</p>
                </div>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-end p-2 border-t border-solid border-slate-200 rounded-b">
              <button
                className="bg-turquoise-light text-white active:bg-turquoise uppercase text-sm px-6 py-3 shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                onClick={handleClose}
              >
                Action
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black" onClick={handleClose}></div>
    </>
  );
}
